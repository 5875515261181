import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";

import "./scss/genericcard.scss";

export default function GenericCard({ imageSrc, header, children }) {
  return (
    <Grid container className="generic-card">
      {imageSrc && (
        <Grid item xs={12}>
          <img src={imageSrc} alt={header} className="main-image" />
        </Grid>
      )}
      <Grid item xs={12}>
        <div className="header-container">
          <Typography
            component="h2"
            variant="subtitle1"
            align="center"
            display="inline"
            className="header"
          >
            {header}
          </Typography>
        </div>
        {children && <div className="content-container">{children}</div>}
      </Grid>
    </Grid>
  );
}

GenericCard.propTypes = {
  imageSrc: PropTypes.string,
  header: PropTypes.string,
  children: PropTypes.any,
};
