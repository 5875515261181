import React from "react";
import PropTypes from "prop-types";
import { GridList, GridListTile, Typography } from "@material-ui/core";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { getRandomInt } from "../utils/random";

import "./scss/articles.scss";
import GenericCard from "./GenericCard";

function Neighborhoods({ neighborhoods, title, width }) {
  const getGridListCols = () => {
    if (isWidthUp("xl", width)) {
      return 5;
    }

    if (isWidthUp("lg", width)) {
      return 4.25;
    }

    if (isWidthUp("md", width)) {
      return 3.25;
    }

    if (isWidthUp("sm", width)) {
      return 2.5;
    }

    return 1.25;
  };

  const generateImage = restaurants => {
    let restaurantIndex = getRandomInt(restaurants.length - 1);
    let knownForIndex = getRandomInt(
      restaurants[restaurantIndex].knownFor.length - 1
    );
    return restaurants[restaurantIndex].knownFor[knownForIndex].photo
      .childImageSharp.resize.src;
  };

  return (
    <React.Fragment>
      <Typography component="h2" variant="h4" className="title">
        {title}
      </Typography>
      <GridList
        className="article-list"
        cols={getGridListCols()}
        spacing={10}
        cellHeight="auto"
      >
        {neighborhoods.map((n, i) => {
          return (
            <GridListTile key={i}>
              <GenericCard
                key={i}
                header={n.node.name}
                url={n.node.url}
                imageSrc={generateImage(n.node.restaurants)}
              />
            </GridListTile>
          );
        })}
      </GridList>
    </React.Fragment>
  );
}

Neighborhoods.propTypes = {
  title: PropTypes.string,
  neighborhoods: PropTypes.array,
};

export default withWidth()(Neighborhoods);
