import React from "react";
import PropTypes from "prop-types";
import { GridList, GridListTile, Typography } from "@material-ui/core";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";

import "./scss/articles.scss";
import GenericCard from "./GenericCard";

function Regions({ regions, title, width }) {
  const getGridListCols = () => {
    if (isWidthUp("xl", width)) {
      return 5;
    }

    if (isWidthUp("lg", width)) {
      return 4.25;
    }

    if (isWidthUp("md", width)) {
      return 3.25;
    }

    if (isWidthUp("sm", width)) {
      return 2.5;
    }

    return 1.25;
  };

  return (
    <React.Fragment>
      <Typography component="h2" variant="h4" className="title">
        {title}
      </Typography>
      <GridList
        className="article-list"
        cols={getGridListCols()}
        spacing={10}
        cellHeight="auto"
      >
        {regions.map((r, i) => {
          return (
            <GridListTile key={i}>
              <GenericCard key={i} header={r.node.name} url={r.node.id} />
            </GridListTile>
          );
        })}
      </GridList>
    </React.Fragment>
  );
}

Regions.propTypes = {
  title: PropTypes.string,
  regions: PropTypes.array,
};

export default withWidth()(Regions);
