import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";
import { Link } from "gatsby";

import "./scss/restaurantsmallcard.scss";

export default function RestaurantSmallCard({
  name,
  slug,
  style,
  knownFor,
  neighborhood,
  imageSrc,
}) {
  const displayKnownForItems = items => {
    items = items.slice(0, 2);
    let foodItems = items.map(i => i.food_item.name);
    console.log("foodItems", foodItems);
    return foodItems.join("/");
  };

  return (
    <div className="restaurant-small-card">
      <Link to={`/restaurants/${slug}`}>
        <Grid container>
          <Grid item xs={12} md={7}>
            <div className="restaurant-info">
              <Typography variant="h6">{name}</Typography>
              <Typography variant="subtitle2" className="restaurant-style">
                {style}
              </Typography>
              <Typography variant="body2">
                <strong>{neighborhood}</strong>
                <br />
                {displayKnownForItems(knownFor)}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={5}>
            <img src={imageSrc} alt={name} className="small-thumnail" />
          </Grid>
        </Grid>
      </Link>
    </div>
  );
}

RestaurantSmallCard.propTypes = {
  name: PropTypes.string,
  style: PropTypes.string,
  slug: PropTypes.string,
  knownFor: PropTypes.array,
  neighborhood: PropTypes.string,
  imageSrc: PropTypes.string,
};

RestaurantSmallCard.defaultProps = {
  knownFor: [],
};
