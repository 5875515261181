import React from "react";
import PropTypes from "prop-types";
import { GridList, GridListTile, Typography } from "@material-ui/core";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";

import DishCard from "./DishCard";

import "./scss/dishes.scss";

function Dishes({ dishes, title, width }) {
  const getGridListCols = () => {
    if (isWidthUp("xl", width)) {
      return 5;
    }

    if (isWidthUp("lg", width)) {
      return 4.25;
    }

    if (isWidthUp("md", width)) {
      return 3.25;
    }

    if (isWidthUp("sm", width)) {
      return 2.5;
    }

    return 1.25;
  };

  const generateImage = photo => {
    return photo ? photo.childImageSharp.resize.src : "";
  };

  return (
    <React.Fragment>
      <Typography component="h2" variant="h4" className="title">
        {title}
      </Typography>
      <GridList
        className="dish-list"
        cols={getGridListCols()}
        spacing={10}
        cellHeight="auto"
      >
        {dishes.map((d, i) => {
          return (
            <GridListTile key={i}>
              <DishCard
                key={i}
                name={d.node.name}
                imageSrc={generateImage(d.node.photo)}
              />
            </GridListTile>
          );
        })}
      </GridList>
    </React.Fragment>
  );
}

Dishes.propTypes = {
  title: PropTypes.string,
  dishes: PropTypes.array,
};

export default withWidth()(Dishes);
