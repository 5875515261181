import React from "react";
import { StaticQuery, graphql } from "gatsby";

import Layout from "../components/Layout";
import Restaurants from "../components/Restaurants";
import { Grid } from "@material-ui/core";

import ArticleMainCard from "../components/ArticleMainCard";
import Articles from "../components/Articles";
import Dishes from "../components/Dishes";
import Neighborhoods from "../components/Neighborhoods";
import Regions from "../components/Regions";

import "./scss/index.scss";
import KnownForDishes from "../components/KnownForDishes";

const IndexPage = () => (
  <Layout>
    <div className="page-container">
      <Grid container>
        <Grid item xs={12}>
          <ArticleMainCard
            imageSrc={`https://loremflickr.com/400/400/tonkotsu-ramen`}
            header="Ramen House"
          >
            <p style={{ textAlign: "center" }}>
              NEW: Check out Ramen House on Dingle St!
            </p>
          </ArticleMainCard>
          {/* <img
          src="https://picsum.photos/seed/picsum/500/500"
          className="main-image"
        /> */}
        </Grid>
      </Grid>
      <StaticQuery
        query={graphql`
          query {
            allStrapiRestaurant {
              edges {
                node {
                  strapiId
                  name
                  slug
                  description
                  cuisines {
                    name
                  }
                  mainImage {
                    absolutePath
                    publicURL
                    childImageSharp {
                      resize(cropFocus: CENTER, height: 400, width: 400) {
                        src
                      }
                    }
                  }
                  knownFor {
                    food_item {
                      name
                    }
                  }
                  neighborhood {
                    name
                  }
                }
              }
            }
            allStrapiArticle {
              edges {
                node {
                  date
                  restaurants {
                    name
                  }
                  slug
                  title
                  mainImage {
                    absolutePath
                    publicURL
                    childImageSharp {
                      resize(cropFocus: CENTER, height: 400, width: 400) {
                        src
                      }
                    }
                  }
                }
              }
            }
            allStrapiFoodItem(
              filter: {
                food_item_tags: { elemMatch: { tag: { eq: "Dishes to Try" } } }
              }
            ) {
              edges {
                node {
                  name
                  photo {
                    childImageSharp {
                      resize(cropFocus: CENTER, height: 400, width: 400) {
                        src
                        height
                        originalName
                        width
                      }
                    }
                  }
                }
              }
            }
            allStrapiNeighborhood {
              edges {
                node {
                  restaurants {
                    knownFor {
                      photo {
                        publicURL
                        childImageSharp {
                          resize(width: 400, height: 400, cropFocus: CENTER) {
                            src
                            width
                            height
                            aspectRatio
                            originalName
                          }
                        }
                      }
                    }
                  }
                  name
                }
              }
            }
            staffPicks: allStrapiRestaurant(
              filter: {
                knownFor: {
                  elemMatch: {
                    food_item_tags: { elemMatch: { tag: { eq: "Staff Pick" } } }
                  }
                }
              }
            ) {
              edges {
                node {
                  strapiId
                  name
                  slug
                  description
                  cuisines {
                    name
                  }
                  mainImage {
                    absolutePath
                    publicURL
                    childImageSharp {
                      resize(cropFocus: CENTER, height: 400, width: 400) {
                        src
                      }
                    }
                  }
                  neighborhood {
                    name
                  }
                  knownFor {
                    name
                    photo {
                      childImageSharp {
                        resize(cropFocus: CENTER, width: 400, height: 400) {
                          height
                          src
                          originalName
                          width
                        }
                      }
                    }
                    food_item_tags {
                      tag
                    }
                  }
                }
              }
            }
            staffPicks: allStrapiRestaurant(
              filter: {
                knownFor: {
                  elemMatch: {
                    food_item_tags: { elemMatch: { tag: { eq: "Staff Pick" } } }
                  }
                }
              }
            ) {
              edges {
                node {
                  strapiId
                  name
                  slug
                  description
                  cuisines {
                    name
                  }
                  mainImage {
                    absolutePath
                    publicURL
                    childImageSharp {
                      resize(cropFocus: CENTER, height: 400, width: 400) {
                        src
                      }
                    }
                  }
                  neighborhood {
                    name
                  }
                  knownFor {
                    food_item {
                      name
                    }
                    name
                    photo {
                      childImageSharp {
                        resize(cropFocus: CENTER, width: 400, height: 400) {
                          height
                          src
                          originalName
                          width
                        }
                      }
                    }
                    food_item_tags {
                      tag
                    }
                  }
                }
              }
            }
            easyToEat: allStrapiRestaurant(
              filter: {
                knownFor: {
                  elemMatch: {
                    food_item_tags: {
                      elemMatch: { tag: { eq: "Easy to Eat" } }
                    }
                  }
                }
              }
            ) {
              edges {
                node {
                  strapiId
                  name
                  slug
                  description
                  cuisines {
                    name
                  }
                  mainImage {
                    absolutePath
                    publicURL
                    childImageSharp {
                      resize(cropFocus: CENTER, height: 400, width: 400) {
                        src
                      }
                    }
                  }
                  neighborhood {
                    name
                  }
                  knownFor {
                    food_item {
                      name
                    }
                    name
                    photo {
                      childImageSharp {
                        resize(cropFocus: CENTER, width: 400, height: 400) {
                          height
                          src
                          originalName
                          width
                        }
                      }
                    }
                    food_item_tags {
                      tag
                    }
                  }
                }
              }
            }
            allStrapiRegion {
              edges {
                node {
                  name
                  strapiId
                }
              }
            }
          }
        `}
        render={data => (
          <div>
            <div>
              <Restaurants
                restaurants={data.allStrapiRestaurant.edges}
                title="Hot &amp; New Restaurants"
              />
            </div>
            <div>
              <Articles
                articles={data.allStrapiArticle.edges}
                title="New Articles"
              />
            </div>
            <div>
              <Dishes
                dishes={data.allStrapiFoodItem.edges}
                title="Dishes To Try"
              />
            </div>
            <div>
              <Neighborhoods
                neighborhoods={data.allStrapiNeighborhood.edges}
                title="Neighborhoods"
              />
            </div>
            <div>
              <Regions regions={data.allStrapiRegion.edges} title="Regions" />
            </div>
            <div>
              <KnownForDishes
                tag="Staff Pick"
                restaurants={data.staffPicks.edges}
                title="Staff Picks"
              />
            </div>
            <div>
              <KnownForDishes
                tag="Easy to Eat"
                restaurants={data.easyToEat.edges}
                title="Easy to Eat"
              />
            </div>
            <div>
              <Restaurants
                restaurants={data.allStrapiRestaurant.edges}
                title="Random Restaurants"
              />
            </div>
          </div>
        )}
      />
    </div>
  </Layout>
);

export default IndexPage;
